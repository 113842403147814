import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {withRouter} from '@computerrock/formation-router';
import {useTranslate} from '@computerrock/formation-i18n';
import {efReturnJourneyTransportationTypes} from '@ace-de/eua-entity-types';
import {useStyles, Panel, DataRow} from '@ace-de/ui-components';
import * as serviceAssignmentSelectors from '../../service-assignments/serviceAssignmentSelectors';

const CostCoverageTravelDataPanel = props => {
    const {cx} = useStyles();
    const {translate, createTranslateShorthand} = useTranslate();
    const translateTab = createTranslateShorthand('sarj_cost_coverage_tab');
    const {serviceAssignment} = props;

    const formatAgeOfKids = () => {
        // Note: If list of years doesn't exist, numberOfKids won't display
        let outputString = serviceAssignment.ageOfKids.length
            ? `${serviceAssignment.numberOfKids} ${translateTab('data_row_label.children')}`
            : '';

        serviceAssignment.ageOfKids.forEach((age, idx) => {
            if (idx === 0) {
                outputString = outputString.concat(' (');
            }
            outputString = idx === serviceAssignment.ageOfKids.length - 1
                ? outputString.concat(`${age} ${translate('global.time_unit.year')})${serviceAssignment.numberOfPets ? ', ' : ''}`)
                : outputString.concat(`${age} ${translate('global.time_unit.year')}, `);
        });
        return outputString;
    };

    return (
        <Panel
            title={translateTab('panel_title.travel_data')}
            qaIdent="sarj-cost-coverage-travel-data"
        >
            <div className={cx('global!ace-u-two-column-layout')}>
                <div>
                    <DataRow
                        label={translateTab('data_row_label.departure_date')}
                        qaIdent="sa-departure-date"
                    >
                        {serviceAssignment.departureDate
                            && `${moment(serviceAssignment.departureDate).format('DD.MM.YYYY[,] HH:mm')} ${translate('global.time_unit.time')}`
                        }
                    </DataRow>
                    <DataRow
                        label={translateTab('data_row_label.transportation_type')}
                        qaIdent="sa-transportation-type-return-journey"
                    >
                        {serviceAssignment.transportationTypeReturnJourney
                            && translate(`global.sarj_transportation_type.${efReturnJourneyTransportationTypes[serviceAssignment.transportationTypeReturnJourney].toLowerCase()}`)
                        }
                    </DataRow>
                    <DataRow
                        label={translateTab('data_row_label.destination')}
                        qaIdent="sa-destination-address"
                    >
                        {serviceAssignment.destination ? serviceAssignment.destination.formattedAddress : ''}
                    </DataRow>
                </div>
                <div>
                    <DataRow
                        label={translateTab('data_row_label.persons')}
                        qaIdent="sa-number-of-adults"
                    >
                        <p>
                            {serviceAssignment.numberOfAdults
                                ? `${serviceAssignment.numberOfAdults} ${translateTab('data_row_label.adults')}${serviceAssignment.numberOfKids ? ', ' : ''}`
                                : ''}
                            {formatAgeOfKids()}
                        </p>
                    </DataRow>
                    <DataRow
                        label={translateTab('data_row_label.animals')}
                        qaIdent="sa-number-of-pets"
                    >
                        {serviceAssignment.numberOfPets > 0 ? `${serviceAssignment.numberOfPets}` : ''}
                    </DataRow>
                    <DataRow
                        label={translateTab('data_row_label.luggage')}
                        qaIdent="sa-amount-of-luggage"
                    >
                        {serviceAssignment.amountOfLuggage > 0 ? serviceAssignment.amountOfLuggage : ''}
                    </DataRow>
                </div>
            </div>
        </Panel>
    );
};

CostCoverageTravelDataPanel.propTypes = {
    serviceAssignment: PropTypes.object,
};

CostCoverageTravelDataPanel.defaultProps = {
    serviceAssignment: null,
};

const mapStateToProps = (state, props) => {
    const serviceAssignmentSelector = serviceAssignmentSelectors.createServiceAssignmentSelector();
    return {
        serviceAssignment: serviceAssignmentSelector(state, props),
    };
};

export default withRouter(connect(mapStateToProps, null)(CostCoverageTravelDataPanel));

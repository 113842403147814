import {put, select, take, fork, call, all} from 'redux-saga/effects';
import {efServiceAssignmentTypes, efServiceCaseTypes, ACEPartner, TemporaryRestriction, apmContractPartnerContractStatusTypes, apmContractPartnerVehicleCompatibilityTypes, ampVehicleFuelTypes, ampVehicleTypes, efServiceTypes, persistenceStates} from '@ace-de/eua-entity-types';
import {arcGISTravelModeTypes} from '@ace-de/eua-arcgis-rest-client';
import fetchRequest from '../../application/sagas/fetchRequest';
import * as serviceAssignmentHelpers from '../../service-assignments/sagas/serviceAssignmentHelpers';
import updateServiceAssignment from '../../service-assignments/sagas/updateServiceAssignment';
import * as savaActionTypes from '../savaActionTypes';
import isTemporaryRestrictionActive from '../../contract-partners/isTemporaryRestrictionActive';
import serviceAssignmentPartnerDisabledStateStatuses from '../../service-assignments/serviceAssignmentPartnerDisabledStateStatuses';
import config from '../../config';
import * as contractPartnerActionTypes from '../../contract-partners/contractPartnerActionTypes';

/**
 * Returns if Contract Partner has service warning (from DTO)
 * @param contractPartnerDTO
 * @returns {boolean}
 */
const hasContractPartnerDTOServicesWarning = contractPartnerDTO => (contractPartnerDTO
    ? !!contractPartnerDTO['isOpeningSoon']
        || !!contractPartnerDTO['isVehicleOverweight']
        || !!contractPartnerDTO['isVehicleTypeIncompatible']
        || !!contractPartnerDTO['isVehicleFuelTypeIncompatible']
        || !!contractPartnerDTO['isCamperIncompatible']
        || !!contractPartnerDTO['isServiceUnsupported']
    : false);

const filterSAVAMapContractPartnersByLocation = function* filterSAVAMapContractPartnersByLocation(
    serviceCaseId,
    serviceAssignmentLineNo,
    isVBASearch = false,
) {
    const {serviceCases} = yield select(state => state.serviceCases);
    const {serviceAssignments} = yield select(state => state.serviceAssignments);
    const {contractPartners} = yield select(state => state.contractPartners);
    const serviceCase = serviceCases[serviceCaseId];
    const serviceAssignmentId = `${serviceCaseId}-${serviceAssignmentLineNo}`;
    const serviceAssignment = serviceAssignments[serviceAssignmentId];
    const {serviceLocation, listOfPreviousPartners = []} = serviceAssignment;
    if (!serviceAssignment || !serviceLocation) return;

    const {serviceManager} = yield select(state => state.application);
    const partnerManagementService = serviceManager.loadService('partnerManagementService');
    const arcGISMapService = serviceManager.loadService('arcGISMapService');
    const arcGISMap = yield call(arcGISMapService.getMap, 'service-assignment-vehicle-additional');
    if (!arcGISMap) return;

    const savaContractPartnerLocationsLayer = yield call(arcGISMap.getLayer, 'sava-contract-partner-locations');
    const savaContractPartnerServiceAreasLayer = yield call(arcGISMap.getLayer, 'sava-contract-partner-service-areas');
    const savaContractPartnerRoutesLayer = yield call(arcGISMap.getLayer, 'sava-contract-partner-routes');
    if (!savaContractPartnerLocationsLayer
        || !savaContractPartnerServiceAreasLayer
        || !savaContractPartnerRoutesLayer) return;

    const prevContractPartnerRecommendations = serviceAssignment?.contractPartnerRecommendations || [];
    const prevRecommendedCPId = serviceAssignment.recommendedContractPartnerId;
    const isPrevSelectedCPRecommended = serviceAssignment?.acePartner?.id
        && contractPartners
        /* eslint-disable-next-line max-len */
        && contractPartners[serviceAssignment.acePartner.id]?.contractStatus === apmContractPartnerContractStatusTypes.ACTIVE
        && serviceAssignment?.recommendedContractPartnerId
        && serviceAssignment.acePartner.id === serviceAssignment.recommendedContractPartnerId;

    yield* serviceAssignmentHelpers.setPersistencePending(serviceAssignmentId);

    yield put({
        type: savaActionTypes.SET_SAVA_CONTRACT_PARTNER_RECOMMENDATIONS,
        payload: {serviceAssignmentId, contractPartnerRecommendationDTOs: []},
    });

    // filter Contract Partner base features (points) by matching available services
    const matchedServicesContractPartnerDTOs = Object.values(contractPartners).map(contractPartner => {
        // only ACTIVE contract partners can be selected - APM should be a source of truth, see ACEMS-997
        /* eslint-disable max-len */
        if (!isVBASearch && contractPartner?.contractStatus !== apmContractPartnerContractStatusTypes.ACTIVE) return null;
        // if it's a VBA search, only VBA partners can be selected
        if (isVBASearch && contractPartner?.contractStatus !== apmContractPartnerContractStatusTypes.FRIENDLY_TOWING_COMPANY) return null;
        /* eslint-enable max-len */
        const contractPartnerDTO = {
            'contractPartnerId': contractPartner.id,
        };
        // check if contract partner is working
        const {isOpen, isOpeningSoon} = contractPartner.isContractPartnerOpen();
        if (!isOpen && !isOpeningSoon) return null;
        if (isOpeningSoon) {
            contractPartnerDTO['isOpeningSoon'] = true;
        }

        // NOTE: temporary fix, ACEECS-4950
        const [service] = contractPartner?.services
            ? contractPartner.services
                .filter(service => service.serviceType === serviceAssignment.serviceType) : [];
        if (!service) {
            contractPartnerDTO['isServiceUnsupported'] = true;
            return contractPartnerDTO;
        }

        // if serviceType is not the basic one, do not perform other checks
        if (![
            efServiceTypes.RECOVERY,
            efServiceTypes.ROADSIDE_ASSISTANCE,
            efServiceTypes.TOWING,
        ].includes(serviceAssignment.serviceType)) return contractPartnerDTO;

        // check if vehicle weight is supported
        const isVehicleWeightSupported = service.tonnageThreshold === config.MIN_ULTRA_HEAVY_WEIGHT_VEHICLE_TONNAGE
            ? true
            : (serviceCase?.vehicle?.weight
                ? (service.tonnageThreshold || 0) >= serviceCase.vehicle.weight : true);
        if (!isVehicleWeightSupported) {
            // set isVehicleOverweight flag, so we can display proper warning in the sidebar
            contractPartnerDTO['isVehicleOverweight'] = true;
        }

        // check if vehicle type is supported
        if (serviceCase?.vehicle?.type === ampVehicleTypes.MOTORBIKE) {
            const isVehicleTypeCompatible = contractPartner?.vehicleCompatibility?.length
                ? contractPartner.vehicleCompatibility
                    .includes(apmContractPartnerVehicleCompatibilityTypes.MOTORBIKE)
                : false;
            if (!isVehicleTypeCompatible) {
                // set isVehicleTypeIncompatible flag, so we can display proper warning in the sidebar
                contractPartnerDTO['isVehicleTypeIncompatible'] = true;
            }
        }

        // check if vehicle fuel type is supported
        if ([ampVehicleFuelTypes.GAS,
            ampVehicleFuelTypes.HYBRID].includes(serviceCase?.vehicle?.fuelType)) {
            const isVehicleFuelTypeCompatible = serviceCase.vehicle.fuelType === ampVehicleFuelTypes.GAS
                ? !!contractPartner?.vehicleCompatibility
                    ?.includes(apmContractPartnerVehicleCompatibilityTypes.CNG_LNG)
                : !!contractPartner?.vehicleCompatibility
                    ?.includes(apmContractPartnerVehicleCompatibilityTypes.HYBRID);
            if (!isVehicleFuelTypeCompatible) {
                // set isVehicleFuelTypeIncompatible flag, so we can display proper warning in the sidebar
                contractPartnerDTO['isVehicleFuelTypeIncompatible'] = true;
            }
        }

        // check if camper is supported
        if (service.camperDimensions && serviceCase?.vehicle?.type === ampVehicleTypes.CAMPER) {
            const isCamperWeightCompatible = typeof service.camperDimensions?.weight === 'number'
                && service.camperDimensions?.weight
                && typeof serviceCase.vehicle?.weight === 'number'
                ? service.camperDimensions.weight >= serviceCase.vehicle.weight : true;
            const isCamperLengthCompatible = typeof service.camperDimensions?.length === 'number'
                && service.camperDimensions?.length
                && typeof serviceCase.vehicle?.length === 'number'
                ? service.camperDimensions.length >= serviceCase.vehicle.length : true;
            const isCamperWidthCompatible = typeof service.camperDimensions?.width === 'number'
                && service.camperDimensions?.width
                && typeof serviceCase.vehicle?.width === 'number'
                ? service.camperDimensions.width >= serviceCase.vehicle.width : true;
            const isCamperHeightCompatible = typeof service.camperDimensions?.height === 'number'
                && service.camperDimensions?.height
                && typeof serviceCase.vehicle?.height === 'number'
                ? service.camperDimensions.height >= serviceCase.vehicle.height : true;
            if (!(isCamperWeightCompatible && isCamperLengthCompatible
                && isCamperWidthCompatible && isCamperHeightCompatible)
            ) {
                // set isCamperIncompatible flag, so we can display proper warning in the sidebar
                contractPartnerDTO['isCamperIncompatible'] = true;
            }
        }
        if (!service.camperDimensions && serviceCase.vehicle?.type === ampVehicleTypes.CAMPER
            && (serviceCase.vehicle?.weight || serviceCase.vehicle?.length
                || serviceCase.vehicle?.width || serviceCase.vehicle?.height)) {
            // set isCamperIncompatible flag, so we can display proper warning in the sidebar
            contractPartnerDTO['isCamperIncompatible'] = true;
        }

        return contractPartnerDTO;
    }).filter(contractPartnerDTO => !!contractPartnerDTO);

    yield put({
        type: savaActionTypes.SET_SAVA_CP_RECOMMENDATIONS_PERSISTENCE_STATE,
        payload: {persistenceState: persistenceStates.PENDING},
    });

    // filter Contract Partner service area features (polygons) by referential point
    yield fork(
        fetchRequest,
        savaActionTypes.FILTER_SAVA_CP_SERVICE_AREAS_BY_REFERENTIAL_POINT_REQUEST,
        savaContractPartnerServiceAreasLayer.filterPolygonFeaturesByReferentialPoint,
        {
            referentialPoint: serviceLocation,
            where: `contractStatus = '${isVBASearch ? apmContractPartnerContractStatusTypes.FRIENDLY_TOWING_COMPANY : apmContractPartnerContractStatusTypes.ACTIVE}' AND contractPa IN (${matchedServicesContractPartnerDTOs
                .map(contractPartnerDTO => `'${contractPartnerDTO['contractPartnerId']}'`)
                .join(', ')})`,
        },
    );

    const filterCPServiceAreasByReferentialPointResponseAction = yield take([
        savaActionTypes.FILTER_SAVA_CP_SERVICE_AREAS_BY_REFERENTIAL_POINT_REQUEST_FAILED,
        savaActionTypes.FILTER_SAVA_CP_SERVICE_AREAS_BY_REFERENTIAL_POINT_REQUEST_SUCCEEDED,
    ]);

    let contractPartnerServiceAreasForReferentialPointDTOs = [];
    if (!filterCPServiceAreasByReferentialPointResponseAction.error) {
        const {response} = filterCPServiceAreasByReferentialPointResponseAction.payload;
        contractPartnerServiceAreasForReferentialPointDTOs = response.featureDTOs || [];
    }

    // filter Contract Partner base features (points) by distance
    yield fork(
        fetchRequest,
        savaActionTypes.FILTER_SAVA_CP_DISTANCE_RECOMMENDATIONS_REQUEST,
        savaContractPartnerLocationsLayer.filterPointFeaturesByDistance,
        {
            distance: 100,
            referentialPoint: serviceLocation,
            returnRoutes: true,
            travelModeType: arcGISTravelModeTypes.TRUCK_SHORTEST_DISTANCE,
            returnLocationFromAttribute: 'address',
            featureCount: 10,
            where: `contractStatus = '${isVBASearch ? apmContractPartnerContractStatusTypes.FRIENDLY_TOWING_COMPANY : apmContractPartnerContractStatusTypes.ACTIVE}' AND contractPa IN (${matchedServicesContractPartnerDTOs
                .map(contractPartnerDTO => `'${contractPartnerDTO['contractPartnerId']}'`)
                .join(', ')})`,
        },
    );

    const filterCPDistanceRecommendationsResponseAction = yield take([
        savaActionTypes.FILTER_SAVA_CP_DISTANCE_RECOMMENDATIONS_REQUEST_FAILED,
        savaActionTypes.FILTER_SAVA_CP_DISTANCE_RECOMMENDATIONS_REQUEST_SUCCEEDED,
    ]);

    let contractPartnerDistanceRecommendationDTOs = [];
    if (!filterCPDistanceRecommendationsResponseAction.error) {
        const {response} = filterCPDistanceRecommendationsResponseAction.payload;
        contractPartnerDistanceRecommendationDTOs = response.featureDTOs;
    }

    // join contractPartnerRecommendation results with matchedServicesContractPartnerDTOs
    contractPartnerDistanceRecommendationDTOs = contractPartnerDistanceRecommendationDTOs.map(contractPartnerDTO => {
        const matchedContractPartnerDTO = matchedServicesContractPartnerDTOs
            .find(contractPartner => contractPartner['contractPartnerId'] === contractPartnerDTO['contractPartnerId']);
        return {
            ...contractPartnerDTO,
            ...(matchedContractPartnerDTO || {}),
        };
    });

    // filter Contract Partner base features (points) by matching service areas
    let contractPartnerServiceAreaRecommendationDTOs = [];
    if (contractPartnerServiceAreasForReferentialPointDTOs.length > 0) {
        yield fork(
            fetchRequest,
            savaActionTypes.FILTER_SAVA_CP_SERVICE_AREA_RECOMMENDATIONS_REQUEST,
            savaContractPartnerLocationsLayer.filterFeaturesByAttribute,
            {
                where: `contractPa IN (${contractPartnerServiceAreasForReferentialPointDTOs
                    .map(contractPartnerDTO => `'${contractPartnerDTO['contractPartnerId']}'`)
                    .join(', ')})`,
                referentialPoint: serviceLocation,
                returnRoutes: true,
                travelModeType: arcGISTravelModeTypes.TRUCK_SHORTEST_DISTANCE,
                returnLocationFromAttribute: 'address',
                keepPreviousResults: true,
            },
        );

        const filterCPServiceAreaRecommendationsResponseAction = yield take([
            savaActionTypes.FILTER_SAVA_CP_SERVICE_AREA_RECOMMENDATIONS_REQUEST_FAILED,
            savaActionTypes.FILTER_SAVA_CP_SERVICE_AREA_RECOMMENDATIONS_REQUEST_SUCCEEDED,
        ]);

        if (!filterCPServiceAreaRecommendationsResponseAction.error) {
            const {response} = filterCPServiceAreaRecommendationsResponseAction.payload;
            contractPartnerServiceAreaRecommendationDTOs = response.featureDTOs;
        }
    }

    // join contract partner recommendation results
    const contractPartnerDistanceRecommendationIds = contractPartnerDistanceRecommendationDTOs
        .map(contractPartnerDTO => contractPartnerDTO['contractPartnerId']);
    let contractPartnerRecommendationDTOs = [
        ...contractPartnerServiceAreaRecommendationDTOs.filter(contractPartnerDTO => {
            return !contractPartnerDistanceRecommendationIds.includes(contractPartnerDTO['contractPartnerId']);
        }),
        ...contractPartnerDistanceRecommendationDTOs,
    ];

    // if current contract partner is not in recommended set, render it in layers
    // the exception is when isVBASearch=true, then do not display previously selected CP
    if (serviceAssignment.acePartner
        && !serviceAssignment.acePartner.isManuallyAdded
        /* eslint-disable max-len */
        && !(isVBASearch && contractPartners && contractPartners[serviceAssignment.acePartner?.id]?.contractStatus !== apmContractPartnerContractStatusTypes.FRIENDLY_TOWING_COMPANY)
        && !(!isVBASearch && contractPartners && contractPartners[serviceAssignment.acePartner?.id]?.contractStatus === apmContractPartnerContractStatusTypes.FRIENDLY_TOWING_COMPANY)
        /* eslint-enable max-len */
        && !contractPartnerRecommendationDTOs
            .find(contractPartnerDTO => {
                return contractPartnerDTO['contractPartnerId'] === serviceAssignment.acePartner.id;
            })) {
        yield fork(
            fetchRequest,
            savaActionTypes.FILTER_SAVA_CP_DISTANCE_RECOMMENDATIONS_REQUEST,
            savaContractPartnerLocationsLayer.filterFeaturesByAttribute,
            {
                where: `contractPa = '${serviceAssignment.acePartner.id}'`,
                referentialPoint: serviceLocation,
                returnRoutes: true,
                travelModeType: arcGISTravelModeTypes.TRUCK_SHORTEST_DISTANCE,
                returnLocationFromAttribute: 'address',
                // keep results from FILTER_SAVA_CP_SERVICE_AREA_RECOMMENDATIONS_REQUEST
                keepPreviousResults: true,
            },
        );

        const filterPointFeaturesResponseAction = yield take([
            savaActionTypes.FILTER_SAVA_CP_DISTANCE_RECOMMENDATIONS_REQUEST_FAILED,
            savaActionTypes.FILTER_SAVA_CP_DISTANCE_RECOMMENDATIONS_REQUEST_SUCCEEDED,
        ]);

        if (!filterPointFeaturesResponseAction.error) {
            const {response} = filterPointFeaturesResponseAction.payload;
            const {featureDTOs: currentContractPartnerRecommendationDTOs = []} = response;
            contractPartnerRecommendationDTOs.push(...currentContractPartnerRecommendationDTOs);
        }
    }

    // sort contract partner recommendation results by distance from service location
    contractPartnerRecommendationDTOs = contractPartnerRecommendationDTOs
        .sort((locationA, locationB) => {
            if (!locationA['routeToDamageLocation']
                || !locationB['routeToDamageLocation']) return 0;

            const {routeToDamageLocation: locationARoute} = locationA;
            const {routeToDamageLocation: locationBRoute} = locationB;
            return (locationARoute.totalKilometers - locationBRoute.totalKilometers);
        });

    // filter Contract Partner service area features (polygons) by distance recommendations
    let contractPartnerServiceAreasForDistanceDTOs = [];
    if (contractPartnerDistanceRecommendationDTOs.length > 0) {
        yield fork(
            fetchRequest,
            savaActionTypes.FILTER_SAVA_CONTRACT_PARTNER_SERVICE_AREAS_REQUEST,
            savaContractPartnerServiceAreasLayer.filterFeaturesByAttribute,
            {
                where: `contractPa IN (${contractPartnerDistanceRecommendationDTOs
                    .map(contractPartnerDTO => `'${contractPartnerDTO['contractPartnerId']}'`)
                    .concat(serviceAssignment?.acePartner?.id ? [`'${serviceAssignment.acePartner.id}'`] : [])
                    .join(', ')})`,
                referentialPoint: serviceLocation,
                // keep results from FILTER_SAVA_CP_SERVICE_AREAS_BY_REFERENTIAL_POINT_REQUEST
                keepPreviousResults: true,
            },
        );

        const filterContractPartnerServiceAreasResponseAction = yield take([
            savaActionTypes.FILTER_SAVA_CONTRACT_PARTNER_SERVICE_AREAS_REQUEST_FAILED,
            savaActionTypes.FILTER_SAVA_CONTRACT_PARTNER_SERVICE_AREAS_REQUEST_SUCCEEDED,
        ]);

        if (!filterContractPartnerServiceAreasResponseAction.error) {
            const {response} = filterContractPartnerServiceAreasResponseAction.payload;
            contractPartnerServiceAreasForDistanceDTOs = response.featureDTOs;
        }
    }

    // join contract partner service area results
    const contractPartnerServiceAreasForDistanceIds = contractPartnerServiceAreasForDistanceDTOs
        .map(contractPartnerDTO => contractPartnerDTO['contractPartnerId']);
    const contractPartnerServiceAreaDTOs = [
        ...contractPartnerServiceAreasForReferentialPointDTOs.filter(contractPartnerDTO => {
            return !contractPartnerServiceAreasForDistanceIds.includes(contractPartnerDTO['contractPartnerId']);
        }),
        ...contractPartnerServiceAreasForDistanceDTOs,
    ];

    // flag contract partner recommendations that have service area
    if (contractPartnerRecommendationDTOs.length > 0 && contractPartnerServiceAreaDTOs.length > 0) {
        const contractPartnerServiceAreaIds = contractPartnerServiceAreaDTOs
            .filter(contractPartnerServiceAreaDTO => contractPartnerServiceAreaDTO['geometry'])
            .map(contractPartnerServiceAreaDTO => contractPartnerServiceAreaDTO['contractPartnerId']);

        // find CPs with service area
        contractPartnerRecommendationDTOs = contractPartnerRecommendationDTOs
            .map(contractPartnerRecommendationDTO => {
                return {
                    ...contractPartnerRecommendationDTO,
                    hasServiceArea: contractPartnerServiceAreaIds.includes(
                        contractPartnerRecommendationDTO['contractPartnerId'],
                    ),
                };
            });
    }

    let recommendedACEPartners = contractPartners;
    // fetch temporary restrictions for recommended contract partners
    // fetch the most recent data from APM
    if (contractPartnerRecommendationDTOs.length > 0) {
        const restrictionsMap = yield call(partnerManagementService.getACEPartnersRestrictions, {
            acePartnerIds: contractPartnerRecommendationDTOs
                .map(contractPartnerRecommendationDTO => contractPartnerRecommendationDTO['contractPartnerId'])
                .filter(Boolean),
        });
        contractPartnerRecommendationDTOs.forEach(contractPartnerRecommendationDTO => {
            if (restrictionsMap && restrictionsMap[contractPartnerRecommendationDTO['contractPartnerId']]) {
                const temporaryRestrictions = new Map();
                restrictionsMap[contractPartnerRecommendationDTO['contractPartnerId']]
                    .forEach(restriction => {
                        const temporaryRestriction = new TemporaryRestriction().fromDTO(restriction);
                        if (isTemporaryRestrictionActive(temporaryRestriction)) {
                            temporaryRestrictions.set(temporaryRestriction.id, temporaryRestriction);
                        }
                    });
                contractPartnerRecommendationDTO.temporaryRestrictions = temporaryRestrictions;
            }
        });
        const {acePartnerDTOs} = yield call(partnerManagementService.getACEPartnersById, {
            acePartnerIds: contractPartnerRecommendationDTOs
                .map(contractPartnerRecommendationDTO => contractPartnerRecommendationDTO['contractPartnerId'])
                .filter(Boolean),
        });
        if (acePartnerDTOs.length > 0) {
            recommendedACEPartners = {};
            acePartnerDTOs.forEach(acePartnerDTO => {
                recommendedACEPartners[acePartnerDTO.id] = new ACEPartner().fromDTO(acePartnerDTO);
            });
            yield put({
                type: contractPartnerActionTypes.STORE_CONTRACT_PARTNERS,
                payload: {contractPartnerDTOs: acePartnerDTOs},
            });
        }
    }

    // patch contractPartnerRecommendationDTOs (from ArcGIS) with APM data
    contractPartnerRecommendationDTOs = contractPartnerRecommendationDTOs.map(contractPartnerDTO => {
        const apmContractPartner = recommendedACEPartners[contractPartnerDTO['contractPartnerId']];
        if (!apmContractPartner) return contractPartnerDTO;

        return apmContractPartner.patchArcGISDataIntoDTO(contractPartnerDTO);
    });

    // render routes for recommended contract partners
    yield call(savaContractPartnerRoutesLayer.setFeatures, {
        features: (contractPartnerRecommendationDTOs || []).map(contractPartnerDTO => {
            const routeToDamageLocation = contractPartnerDTO['routeToDamageLocation'];
            if (!routeToDamageLocation) return null;
            return {
                attributes: {
                    'FID': contractPartnerDTO['featureId'],
                    'contractPa': contractPartnerDTO['contractPartnerId'],
                    'Name': contractPartnerDTO['contractPartnerName'],
                },
                geometry: routeToDamageLocation.geometry,
            };
        }).filter(Boolean),
    });

    // find service areas containing service location (bestMatchingContractPartnerIds)
    let bestMatchingContractPartnerIds = [];
    if (contractPartnerServiceAreaDTOs.length > 0) {
        const bestMatchingContractPartnerDTOs = contractPartnerServiceAreaDTOs
            .filter(contractPartnerServiceAreaDTO => contractPartnerServiceAreaDTO['containsDamageLocation'])
            .map(contractPartnerServiceAreaDTO => contractPartnerRecommendationDTOs.find(contractPartnerDTO => {
                return contractPartnerDTO['contractPartnerId'] === contractPartnerServiceAreaDTO['contractPartnerId'];
            }))
            // fix https://computerrock.atlassian.net/browse/ACEECS-5885
            // invalid CP -> only polygon exists, a point does not exist on the layer (location = null)
            // find method returns undefined => bestMatchingContractPartnerDTOs = [undefined]
            .filter(contractPartnerDTO => !!contractPartnerDTO);

        // filter-out any area best matching CP if it has service warning, so the next matching step can
        // prefer CPs by distance: https://computerrock.atlassian.net/browse/ACEECS-4825
        bestMatchingContractPartnerIds = bestMatchingContractPartnerDTOs
            .filter(contractPartnerDTO => !hasContractPartnerDTOServicesWarning(contractPartnerDTO))
            .map(contractPartnerDTO => contractPartnerDTO['contractPartnerId']);
    }

    // find candidate contract partner based on distance from service location
    let nearestRoute;
    let recommendedContractPartnerDTO;
    (bestMatchingContractPartnerIds.length === 0
        ? contractPartnerRecommendationDTOs
        : contractPartnerRecommendationDTOs.filter(contractPartnerDTO => {
            return bestMatchingContractPartnerIds.includes(contractPartnerDTO['contractPartnerId']);
        }))
        .forEach(contractPartnerDTO => {
            const routeToDamageLocationDTO = contractPartnerDTO['routeToDamageLocation'];
            if (!routeToDamageLocationDTO) return;
            if ((routeToDamageLocationDTO['totalKilometers'] < nearestRoute || !recommendedContractPartnerDTO)
                // filter-out any CP if it has service warning
                // https://computerrock.atlassian.net/browse/ACEECS-4825
                && !hasContractPartnerDTOServicesWarning(contractPartnerDTO)) {
                recommendedContractPartnerDTO = contractPartnerDTO;
                nearestRoute = routeToDamageLocationDTO['totalKilometers'];
            }
        });

    // select features for current contract partner or the recommended one
    const selectedContractPartner = serviceAssignment.acePartner?.isManuallyAdded
        ? serviceAssignment.acePartner
        : (serviceAssignment.acePartner?.id
            ? contractPartners[serviceAssignment.acePartner.id] || null : null);
    const previousPartners = serviceAssignment.listOfPreviousPartners || [];
    /* eslint-disable max-len */
    const selectedContractPartnerId = selectedContractPartner?.isManuallyAdded
        ? null
        : (selectedContractPartner
            && ((isVBASearch && selectedContractPartner.contractStatus === apmContractPartnerContractStatusTypes.FRIENDLY_TOWING_COMPANY)
                || (!isVBASearch && selectedContractPartner.contractStatus === apmContractPartnerContractStatusTypes.ACTIVE))
            ? selectedContractPartner.id
            : (recommendedContractPartnerDTO
                && !(previousPartners?.length && previousPartners.includes(recommendedContractPartnerDTO['contractPartnerId']))
                ? recommendedContractPartnerDTO['contractPartnerId'] : null));
    /* eslint-enable max-len */
    if (selectedContractPartnerId) {
        yield all([
            call(savaContractPartnerLocationsLayer.selectFeatureByAttribute, {
                where: `contractPa = '${selectedContractPartnerId}'`,
            }),
            call(savaContractPartnerServiceAreasLayer.selectFeatureByAttribute, {
                where: `contractPa = '${selectedContractPartnerId}'`,
            }),
            call(savaContractPartnerRoutesLayer.selectFeatureByAttribute, {
                where: `contractPa = '${selectedContractPartnerId}'`,
            }),
        ]);
    }

    // save recommended contract partner as selected if no contract partner was previously set
    // save recommended contract partner as selected if VBA search is active, and currently selected
    // contract partner is not VBA partner
    if (recommendedContractPartnerDTO
        && !(previousPartners?.length && previousPartners.includes(recommendedContractPartnerDTO['contractPartnerId']))
        && (!serviceAssignment.acePartner || (
            selectedContractPartnerId && selectedContractPartnerId !== selectedContractPartner?.id
        ))
    ) {
        const recommendedContractPartner = new ACEPartner()
            .fromDTO(recommendedACEPartners[recommendedContractPartnerDTO.id] || null);
        yield* updateServiceAssignment({
            caller: 'LOAD_SAVA_CONTRACT_PARTNER_RECOMMENDATIONS',
            assignmentType: efServiceAssignmentTypes.VEHICLE,
            serviceAssignmentLineNo,
            serviceCaseId,
            serviceAssignmentData: {
                acePartner: {
                    ...recommendedContractPartner,
                    ...(recommendedContractPartner.businessContactDetails
                        ? {
                            businessContactDetails: {
                                ...recommendedContractPartner.businessContactDetails,
                                email: recommendedContractPartner?.assignmentEmail || '',
                                faxNo: recommendedContractPartner?.assignmentFaxNo || '',
                                phoneNo: recommendedContractPartner?.emergencyContacts?.find(contact => contact.is24h7Emergency)?.phoneNo || '',
                            },
                            contactDetails: null,
                        } : {}
                    ),
                    rank: config.RECOMMENDED_CONTRACT_PARTNER_RANK, // recommended CP has always a rank of 1
                },
                assignmentText: null,
            },
        });

        // set persistence state back to PENDING
        yield* serviceAssignmentHelpers.setPersistencePending(serviceAssignmentId);
    }

    yield put({
        type: savaActionTypes.SET_SAVA_CONTRACT_PARTNER_RECOMMENDATIONS,
        payload: {
            serviceAssignmentId,
            contractPartnerRecommendationDTOs,
            recommendedContractPartnerId: recommendedContractPartnerDTO
                ? recommendedContractPartnerDTO['contractPartnerId'] : null,
        },
    });

    yield put({
        type: savaActionTypes.SET_SAVA_CP_RECOMMENDATIONS_PERSISTENCE_STATE,
        payload: {persistenceState: persistenceStates.READY},
    });

    yield all([
        call(savaContractPartnerLocationsLayer.disableFeatureByAttribute, {
            where: `contractPa IN (${(listOfPreviousPartners || [])
                .map(contractPartnerId => `'${contractPartnerId}'`)
                .join(', ')})`,
        }),
        call(savaContractPartnerRoutesLayer.disableFeatureByAttribute, {
            where: `contractPa IN (${(listOfPreviousPartners || [])
                .map(contractPartnerId => `'${contractPartnerId}'`)
                .join(', ')})`,
        }),
        call(savaContractPartnerServiceAreasLayer.disableFeatureByAttribute, {
            where: `contractPa IN (${(listOfPreviousPartners || [])
                .map(contractPartnerId => `'${contractPartnerId}'`)
                .join(', ')})`,
        }),
    ]);

    // display layers
    savaContractPartnerLocationsLayer.show();
    savaContractPartnerServiceAreasLayer.show();
    savaContractPartnerRoutesLayer.show();

    const isPartnerChangingDisabled = serviceCase?.caseType === efServiceCaseTypes.VEHICLE
        && serviceAssignmentPartnerDisabledStateStatuses.includes(serviceAssignment?.assignmentStatus);
    // if ace partner is entered manually, disable locations layer
    if (serviceAssignment?.acePartner?.isManuallyAdded || isPartnerChangingDisabled) {
        savaContractPartnerLocationsLayer.toggleLayerDisabled(true);
    }

    yield* serviceAssignmentHelpers.setPersistenceReady(serviceAssignmentId);

    // if it's a VBA search and there is a previously selected ACTIVE recommended CP
    // and in the list of the VBA partners there is a recommended one
    // then open a modal for creating a QM note
    if (isVBASearch && isPrevSelectedCPRecommended && recommendedContractPartnerDTO) {
        yield put({
            type: savaActionTypes.INITIATE_SAVA_VBA_PARTNER_QM_FEEDBACK_DRAFT_CREATION,
            payload: {
                serviceCaseId,
                serviceAssignmentLineNo,
                recommendedCPId: prevRecommendedCPId,
                contractPartnerRecommendations: prevContractPartnerRecommendations,
            },
        });
    }
};

export default filterSAVAMapContractPartnersByLocation;
